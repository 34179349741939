import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import { Search } from '@material-ui/icons';

function SearchButton() {
    const { height, mainColor } = useState(hive.state)


    return <div style={{
        position: "absolute",
        // pointerEvents: "none",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        zIndex: 1,
    }}>
        <div
            onClick={() => { hive.action("setScene", "search") }}
            style={{
                width: height.get() * 0.30,
                height: height.get() * 0.027,
                lineHeight: height.get() * 0.027 + "px",
                // background: `#${mainColor.get()}`,
                background: `#3b1136`,
                color: "white",
                textAlign: "center",
                fontSize: height.get() * 0.022 + "px",
                borderBottomLeftRadius: height.get() * 0.01 + "px",
                borderBottomRightRadius: height.get() * 0.01 + "px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                pointerEvents: "initial",
                userSelect: "none",
            }}>
            <div>
                <Search style={{
                    color: "#e1e4e5",
                    width: height.get() * 0.025 + "px",
                    height: height.get() * 0.025 + "px",
                }}></Search>
            </div>
            <div>
                RECHERCHEZ UN BIEN
        </div>
        </div>
    </div>
}

export default SearchButton  